<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          color="primary"
          outlined
          block
          :disabled="items.length === 0"
          @click="dowloadExportCsv()"
        >
          <v-icon class="mr-2">
            mdi-tray-arrow-down
          </v-icon>
          {{ `${$t('btn.export')} CSV` }}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        v-if="tabla"
        cols="12"
        md="2"
        class="text-lowercase"
      >
        <span class="pt-5">
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} <span class="text-lower">{{ tabla.name }}</span>
        </span>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="itemsPerPage"
          :value="itemsPerPage"
          :label="$t('lbl.itemsPerPage')"
          type="number"
          min="1"
          max="15"
          dense
          outlined
          disabled
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          dense
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            {{ $t('lbl.notShowElement') }}
          </template>
          <template v-slot:item.Características="{ item }">
            <v-tooltip
              top
              max-width="45%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.Características }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.Intereses="{ item }">
            <v-tooltip
              top
              max-width="45%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.Intereses }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.Servicios="{ item }">
            <v-tooltip
              top
              max-width="45%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.Servicios }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.Descripción="{ item }">
            <v-tooltip
              top
              max-width="45%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span v-html="item.Descripción"></span>
            </v-tooltip>
          </template>
          <template v-slot:item.Habitaciones="{ item }">
            <v-tooltip
              v-if="item.Habitaciones"
              top
              max-width="45%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.Habitaciones }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-card-text class="pt-2">
          <v-row>
            <v-col
              lg="12"
              cols="12"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
} from '@mdi/js'

import exportFromJSON from 'export-from-json'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tabla: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    headers: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    items: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    totalF: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    totalItems: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
      },
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    dowloadExportCsv() {
      const name = `${this.tabla.name}_${this.$moment(new Date()).format('D-MMM-YY')}`
      this.exportCsv(name, this.items, 'csv', this.fields)
    },

    exportCsv(newFileName, data, fileExportType) {
      const fileName = newFileName || 'exported-data'
      const exportType = exportFromJSON.types[fileExportType || 'csv']
      exportFromJSON({
        data,
        fileName,
        exportType,
      })
    },
  },
}
</script>
